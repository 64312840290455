// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../assets/styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@supports (animation-timeline: scroll()) {
    @keyframes scaleProgress {
        from {
            transform: scaleX(0);
        }

        to {
            transform: scaleX(1);
        }
    }

    .progress-bar {
        left: 0;
        right: 0;
        height: 10px;
        animation: scaleProgress linear;
        animation-timeline: scroll();
        transform-origin: left;
        background-color: red;
    }

}

/* .circle{
    position: relative;
    top: 0;
    left: 100px;
    right: 0;
    width: 15px;
    height: 15px;
    background-color:red;
    animation: scaleProgress linear;
    animation-timeline: scroll();
    transform-origin: left;
} */`, "",{"version":3,"sources":["webpack://./src/components/progressbar/progressBar.css"],"names":[],"mappings":"AAEA;IACI;QACI;YACI,oBAAoB;QACxB;;QAEA;YACI,oBAAoB;QACxB;IACJ;;IAEA;QACI,OAAO;QACP,QAAQ;QACR,YAAY;QACZ,+BAA+B;QAC/B,4BAA4B;QAC5B,sBAAsB;QACtB,qBAAqB;IACzB;;AAEJ;;AAEA;;;;;;;;;;;GAWG","sourcesContent":["@import '../../assets/styles/global.css';\n\n@supports (animation-timeline: scroll()) {\n    @keyframes scaleProgress {\n        from {\n            transform: scaleX(0);\n        }\n\n        to {\n            transform: scaleX(1);\n        }\n    }\n\n    .progress-bar {\n        left: 0;\n        right: 0;\n        height: 10px;\n        animation: scaleProgress linear;\n        animation-timeline: scroll();\n        transform-origin: left;\n        background-color: red;\n    }\n\n}\n\n/* .circle{\n    position: relative;\n    top: 0;\n    left: 100px;\n    right: 0;\n    width: 15px;\n    height: 15px;\n    background-color:red;\n    animation: scaleProgress linear;\n    animation-timeline: scroll();\n    transform-origin: left;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
