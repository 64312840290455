import React from 'react'
import icono1 from '../../assets/images/about.png';
import icono2 from '../../assets/images/about2.png';
import icono3 from '../../assets/images/about3.png';
import icono4 from '../../assets/images/about4.png';
import RadariaFeed from '../../assets/images/RADARIA FEED.png'
import '../../Views/Home/styles/aboutHome-styles.css'
  
const AboutHome = () => {
  return (
    <>
      <div className='container-fluid'>
        {/** Seccion negra con iconos*/}
        <div className='row bg-dark text-white py-4 text-center'>
          <div className='col-md-3 text-center'>
              <img src={icono1} alt="Icono 1" className="img-fluid mb-2" />
                <div className='users-count'>
                  <p className='number'>1500</p>
                  <p className='label'> Usuarios </p>
                </div>
          </div>
          <div className='col-md-3 text-center'>
              <img src={icono2} alt='Icono 2' className='img-fluid mb-2' />
                <div>
                  <p className='number'>1500</p>
                  <p className='label'> Usuarios </p>
                </div>
          </div>
          <div className='col-md-3 text-center'>
              <img src={icono3} alt='Icono 3' className='img-fluid mb-2'/>
                <div>
                  <p className='number'>1500</p>
                  <p className='label'> Usuarios </p>
                </div>
          </div>
          <div className='col-md-3 text-center'>
              <img src={icono4} alt='Icono 4' className='img-fluid mb-2'/>
                <div>
                  <p className='number'>1500</p>
                  <p className='label'> Usuarios </p>
                </div>
          </div>
        </div>
        {/**Seccion de contenido */}
        <div className='row my-5 contenido-bg'>
          <div className='col-md-6'>
            <h2 className='title font-visbycf text-center'> Titulo para contar mas sobre radaria </h2>
            <p className='description font-hankengrotesk text-center'>
            El isotipo muestra de manera abstracta la pista de  running combinando la “r” de RADARIA. Este se utiliza como un símbolo para la marca,  transmitiendo la idea de que la aplicación está diseñada  para ser fácil de usar, y para todos aquellos que van  empezando o llevan un tiempo en el deporte. Esto es poderoso para la identidad de la marca, ya que  puede ser instantáneamente identificable
            </p>
          </div>
          <div className='col-md-6 text-center'>
            <div className='image-wrapper d-inline-block p-4 '>
              <img src={RadariaFeed} alt='Radaria Feed' className='img-container'/>
            </div>
          </div>
        </div>
      </div>
    </>
    
  )
}

export default AboutHome
