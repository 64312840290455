// import { Component } from "react";
import Home from "../Views/Home/Home";
import CreateAccount from "../Views/CreateAccount/CreateAccount";
import AboutUs from "../Views/AboutUs/About";
import ValidateInfo from "../Views/ValidateInfo/ValidateInfo";
import Membreships from "../Views/Memberships/Memberships";
import Coaches from "../Views/Coaches/Coaches";
import Services from "../Views/Services/Services";


export const routes = [
  {
    name: "Home",
    path: "/",
    Component: Home,
  },
  {
    name: "Auth",
    path: "/auth",
    Component: CreateAccount,
  },
  {
    name: "Verified",
    path: "/vreifyInformation",
    Component: ValidateInfo,
  },
  {
    name: 'AboutUs',
    path: '/about-us',
    Component: AboutUs,
  },
  {
    name: 'Memberships',
    path: '/memberships',
    Component: Membreships,
  },
  {
    name: 'Coaches',
    path: '/coaches',
    Component: Coaches,
  },
  {
    name: 'Services',
    path: '/services',
    Component: Services,
  }
];
