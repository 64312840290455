import React from "react";
import './validateInfo.css';
//Components
import Navbar from "../../components/navbar/navbar";
import FormValidateInfo from "./FormVlidateInfo/FormValidate";
//Assets



const ValidateInfo = () => {


    return (<div className='container_verified'>
        <Navbar />
        <div className='container d-flex justify-content-center align-items-center'>
            <div className='container-form-info mb-5  p-3'>
                <div className="welcome-info">
                    Welcome to <span>Radaria</span> <span>Ernesto</span>!
                </div>
                <FormValidateInfo />
            </div>
        </div>
    </div>

    );
};

export default ValidateInfo;