// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-container{
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;
  cursor: grab;
  margin: 0 auto;
  padding: 30px 0;
  background-color: var(--primary);
}

.slider-track{
  display: flex;
  gap: 30px;
  animation: scroll 20s linear infinite;
}

.slider-item {
  flex: 0 0 auto;
  min-width: 300px;
  height: 100%;
  transition: transform 0.2s ease-in-out;
}

.slider-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;

}

/* keyframe de efecto desplazamineto :))))) */
@keyframes scroll {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(-100%);
  }
}
/* Pausar el scrooll cuando se pasa el cursor*/
.slider-container:hover .slider-track{
  animation-play-state: paused;
}

`, "",{"version":3,"sources":["webpack://./src/Views/Home/styles/carouselHome-styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,SAAS;EACT,qCAAqC;AACvC;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,YAAY;EACZ,sCAAsC;AACxC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;;AAErB;;AAEA,6CAA6C;AAC7C;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,4BAA4B;EAC9B;AACF;AACA,8CAA8C;AAC9C;EACE,4BAA4B;AAC9B","sourcesContent":[".slider-container{\r\n  position: relative;\r\n  width: 100%;\r\n  height: 350px;\r\n  overflow: hidden;\r\n  cursor: grab;\r\n  margin: 0 auto;\r\n  padding: 30px 0;\r\n  background-color: var(--primary);\r\n}\r\n\r\n.slider-track{\r\n  display: flex;\r\n  gap: 30px;\r\n  animation: scroll 20s linear infinite;\r\n}\r\n\r\n.slider-item {\r\n  flex: 0 0 auto;\r\n  min-width: 300px;\r\n  height: 100%;\r\n  transition: transform 0.2s ease-in-out;\r\n}\r\n\r\n.slider-item img {\r\n  width: 100%;\r\n  height: 100%;\r\n  object-fit: cover;\r\n  border-radius: 15px;\r\n\r\n}\r\n\r\n/* keyframe de efecto desplazamineto :))))) */\r\n@keyframes scroll {\r\n  0%{\r\n    transform: translateX(0);\r\n  }\r\n  100%{\r\n    transform: translateX(-100%);\r\n  }\r\n}\r\n/* Pausar el scrooll cuando se pasa el cursor*/\r\n.slider-container:hover .slider-track{\r\n  animation-play-state: paused;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
