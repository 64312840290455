// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../assets/styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contenido-bg {
    background-color: #D4D8D8;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title{
    font-family: 'VisibyCF', sans-serif;
    font-size: 24px;
    margin-bottom: 15px;
}

.users-count {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.description {
    font-family: 'HankenGrotesk', sans-serif;
    font-size: 16px;
    color: #333;
  }
  
  .image-wrapper {
    background-color: #D4D8D8;
    padding: 50px; /* Añade padding para dar espacio dentro del contenedor */
    border-radius: 20px; /* Bordes redondeados */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .img-container {
    max-width: 100%;
    height: auto;
  }

 .number{
    font-size: 30px; /* Ajusta el tamaño de fuente según lo necesites */
    margin: 0;
    font-family: 'VisibyCF', sans-serif;
  }
  
.label{
    font-size: 16px; /* Ajusta el tamaño de fuente según lo necesites */
    margin: 0;
    font-family: 'HankenGrotesk', sans-serif;
  }
  `, "",{"version":3,"sources":["webpack://./src/Views/Home/styles/aboutHome-styles.css"],"names":[],"mappings":"AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,mCAAmC;IACnC,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;AAEF;IACI,wCAAwC;IACxC,eAAe;IACf,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,aAAa,EAAE,yDAAyD;IACxE,mBAAmB,EAAE,uBAAuB;IAC5C,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,eAAe;IACf,YAAY;EACd;;CAED;IACG,eAAe,EAAE,kDAAkD;IACnE,SAAS;IACT,mCAAmC;EACrC;;AAEF;IACI,eAAe,EAAE,kDAAkD;IACnE,SAAS;IACT,wCAAwC;EAC1C","sourcesContent":["@import '../../../assets/styles/global.css';\r\n\r\n.contenido-bg {\r\n    background-color: #D4D8D8;\r\n    padding: 50px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.title{\r\n    font-family: 'VisibyCF', sans-serif;\r\n    font-size: 24px;\r\n    margin-bottom: 15px;\r\n}\r\n\r\n.users-count {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n  }\r\n\r\n.description {\r\n    font-family: 'HankenGrotesk', sans-serif;\r\n    font-size: 16px;\r\n    color: #333;\r\n  }\r\n  \r\n  .image-wrapper {\r\n    background-color: #D4D8D8;\r\n    padding: 50px; /* Añade padding para dar espacio dentro del contenedor */\r\n    border-radius: 20px; /* Bordes redondeados */\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n  \r\n  .img-container {\r\n    max-width: 100%;\r\n    height: auto;\r\n  }\r\n\r\n .number{\r\n    font-size: 30px; /* Ajusta el tamaño de fuente según lo necesites */\r\n    margin: 0;\r\n    font-family: 'VisibyCF', sans-serif;\r\n  }\r\n  \r\n.label{\r\n    font-size: 16px; /* Ajusta el tamaño de fuente según lo necesites */\r\n    margin: 0;\r\n    font-family: 'HankenGrotesk', sans-serif;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
