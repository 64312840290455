import React, { useState } from "react";
import './formSignUp.css';
import validateForm from './Validate'
import apiService from "../../../api/userService";

const FormSignUp = () => {
    const initialFormValues = {
        firstName: "",
        email: "",
        password: "",
        confirmPass: ""
    }
    const [acceptTerms, setAcceptTerms] = useState(false)
    const [formValues, setFormValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setAcceptTerms(e.target.checked);

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formValues);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            const userData = { ...formValues };
            delete userData['confirmPass'];
            delete userData[''];
            userData.status = "inactive";

            if (acceptTerms) {
                console.log('Formulario válido, enviando datos...', userData);
                apiService.registerUser(userData);
                // Aquí enviarías los datos a la API
                alert('Registro exitoso');
                setAcceptTerms(false);
                setFormValues(initialFormValues)
                setErrors({});
            }
            setErrors({});
        }

    };

    return (
        <div className="d-flex flex-column align-items-center">
            <form onSubmit={handleSubmit} className="form-sign-up needs-validation">
                <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">FIRST NAME</label>
                    <input
                        type="text"
                        className={`form-control ${errors.firstName && "is-invalid input-error"} text-light`}
                        id="firstName"
                        name="firstName"
                        value={formValues.firstName}
                        onChange={handleChange}
                        placeholder="Your name"
                    />
                    {errors.firstName &&
                        <div id="validationServer05Feedback" className="invalid-feedback">
                            {errors.firstName}
                        </div>
                    }
                </div>

                <div className="mb-3">
                    <label htmlFor="inputEmail" className="form-label">EMAIL ADRESS</label>
                    <input
                        type="email"
                        name="email"
                        value={formValues.email}
                        onChange={handleChange}
                        className={`form-control ${errors.email && "is-invalid input-error"} text-light`}
                        id="inputEmai"
                        placeholder="name@example.com"
                    />
                    {errors.email &&
                        <div id="validationServer05Feedback" className="invalid-feedback">
                            {errors.email}
                        </div>
                    }
                </div>
                <div className="">
                    <label htmlFor="inputPasswordLogin" className="form-label" >PASSWORD</label>
                    <input
                        type="password"
                        name="password"
                        value={formValues.password}
                        onChange={handleChange}
                        className={`form-control ${errors.password && "is-invalid input-error"} text-light`}
                        id="inputPasswordLogin"
                        placeholder="Your password"
                    />
                    {errors.password &&
                        <div id="validationServer05Feedback" className="invalid-feedback">
                            {errors.password}
                        </div>
                    }
                </div>
                <div className="">
                    <label htmlFor="inputPasswordLogin" className="form-label" >CONFIRM PASSWORD</label>
                    <input
                        type="password"
                        name="confirmPass"
                        value={formValues.confirmPass}
                        onChange={handleChange}
                        className={`form-control ${errors.confirmPass && "is-invalid input-error"} text-light`}
                        id="inputPasswordLogin"
                        placeholder="Password"
                    />
                    {errors.confirmPass &&
                        <div id="validationServer05Feedback" className="invalid-feedback">
                            {errors.confirmPass}
                        </div>
                    }
                </div>
                <div className="d-flex flex-column justify-content-center">
                    <div className="d-flex align-items-center justify-content-center m-2 form-check">
                        <input
                            type="checkbox"
                            checked={acceptTerms}
                            className={`form-check-input mt-0 ${!acceptTerms && "is-invalid input-error border border-danger"}`}
                            onChange={handleChange}
                            id="checkTerms" />
                        <label
                            htmlFor="checkTerms"
                            className="form-check-label ms-2">Accept terms</label>
                    </div>
                    <button type="submit" className="btn btn-primary">CREATE</button>
                </div>
            </form>
        </div>
    );

};

export default FormSignUp;