// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-sign-up{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 500px;
}
.form-sign-up div{
    flex: auto 1 auto; /* 3 columnas por defecto */
    width: 200px; /* Tamaño mínimo para cada columna */
    padding: 5px; 
}
/* Move */
form {
    font-family: CodecProRegular;
    font-size: 16px;
    justify-content: center;
    align-items: center;
}
.input-error{
    border: none !important;
    border-bottom: 1px solid var(--bs-danger) !important;
}
input {
    height: 50px;
    width:200px;
    background-color: transparent !important;
    border-bottom: 1px solid white !important;
}`, "",{"version":3,"sources":["webpack://./src/Views/CreateAccount/FormSignUp/formSignUp.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,gBAAgB;AACpB;AACA;IACI,iBAAiB,EAAE,2BAA2B;IAC9C,YAAY,EAAE,oCAAoC;IAClD,YAAY;AAChB;AACA,SAAS;AACT;IACI,4BAA4B;IAC5B,eAAe;IACf,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,uBAAuB;IACvB,oDAAoD;AACxD;AACA;IACI,YAAY;IACZ,WAAW;IACX,wCAAwC;IACxC,yCAAyC;AAC7C","sourcesContent":[".form-sign-up{\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n    max-width: 500px;\n}\n.form-sign-up div{\n    flex: auto 1 auto; /* 3 columnas por defecto */\n    width: 200px; /* Tamaño mínimo para cada columna */\n    padding: 5px; \n}\n/* Move */\nform {\n    font-family: CodecProRegular;\n    font-size: 16px;\n    justify-content: center;\n    align-items: center;\n}\n.input-error{\n    border: none !important;\n    border-bottom: 1px solid var(--bs-danger) !important;\n}\ninput {\n    height: 50px;\n    width:200px;\n    background-color: transparent !important;\n    border-bottom: 1px solid white !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
