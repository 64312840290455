import React, { useState } from "react";
import './formvalidate.css'
//Assets
import profileUser from '../../../assets/images/profile1.webp'
//Service
import apiService from "../../../api/userService";

const FormValidateInfo = () => {
    const [formValues, setFormValues] = useState({
        firstName: "",
        lastName: "",
        dateBirth: "",
        nickname: "",
        phoneNumber: "",
        gender: ""
    });
    const [errors, setErrors] = useState({});

    const validate = () => {
        const errors = {};
        // Name validation
        if (!formValues.firstName.trim()) {
            errors.firstName = 'The name is required';
        } else if (formValues.firstName.length < 2) {
            errors.firstName = 'The name must be at least 2 characters';
        }
        //Last Name validation
        if (!formValues.lastName.trim()) {
            errors.lastName = 'The lastname is required';
        } else if (formValues.lastName.length < 2) {
            errors.lastName = 'The lastname must be at least 2 characters';
        }
        //Birthdate validate

        //NickName validate
        if (!formValues.nickname.trim()) {
            errors.nickname = 'The nickname is required';
        } else if (formValues.nickname.length < 2) {
            errors.nickname = 'The nickname must be at least 2 characters';
        }

        //Phone validate
        if (!formValues.phoneNumber) {
            errors.phoneNumber = 'The phone is required';
        } else if (!/^[0-9]{10}$/.test(formValues.phoneNumber)) {
            errors.phoneNumber = 'Invalida phone number'
        }
        //Gender validate
        if (formValues.gender === '') {
            errors.gender = 'The gender is required';
        } else if (formValues.gender !== 'Masculino' && formValues.gender !== 'Femenino' && formValues.gender !== 'Otro') {
            errors.gender = 'The gender is invalid';
        }

        return errors;

    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });


    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        console.log(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            const userData = { ...formValues };
            console.log(userData);
            userData.status = "pending";
            userData.phoneNumber = '+52' + formValues.phoneNumber;
            console.log('Formulario válido, enviando datos...', userData);
            // Aquí enviarías los datos a la API
            const responseApi = apiService.completRegister(userData)
            console.log(responseApi);

            setErrors({});
            alert('Registro exitoso');
        }

    };


    return (
        <div className="d-flex flex-column align-items-center mt-3">
            <form onSubmit={handleSubmit} className="d-flex flex-column needs-validation">
                <div className="container-head-form" >
                    <div className="container-img">
                        {/* <input type="file" accept="image/*" /> */}
                        {<img src={profileUser} alt="Perfil" style={{ width: '150px', height: '150px', borderRadius: '50%' }} />}
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            name="nickname"
                            value={formValues.nickname}
                            onChange={handleChange}
                            className={`form-control ${errors.nickname && "is-invalid input-error"} text-light`}
                            id="nickName"
                            placeholder="Your nickname"
                        />
                        {errors.nickname &&
                            <div id="validationServer05Feedback" class="invalid-feedback">
                                {errors.nickname}
                            </div>
                        }
                    </div>
                </div>
                <div className="form-validate-info mt1">
                    <div className="mb-3">
                        <label for="firstName" className="form-label">FIRST NAME</label>
                        <input
                            type="text"
                            className={`form-control ${errors.firstName && "is-invalid input-error"} text-light`}
                            id="firstName"
                            name="firstName"
                            value={formValues.firstName}
                            onChange={handleChange}
                            placeholder="Your name"
                        />
                        {errors.firstName &&
                            <div id="validationServer05Feedback" class="invalid-feedback">
                                {errors.firstName}
                            </div>
                        }
                    </div>
                    <div className="">
                        <label for="lastName" className="form-label">LAST NAME</label>
                        <input
                            type="text"
                            name="lastName"
                            value={formValues.lastName}
                            onChange={handleChange}
                            className={`form-control ${errors.lastName && "is-invalid input-error"} text-light`}
                            id="lastName"
                            placeholder=" Your last name"
                        />
                        {errors.lastName &&
                            <div id="validationServer05Feedback" class="invalid-feedback">
                                {errors.lastName}
                            </div>
                        }
                    </div>
                    <div className="mb-3">
                        <label for="birthdate" className="form-label">BIRTHDATE</label>
                        <input
                            type="date"
                            name="dateBirth"
                            value={formValues.dateBirth}
                            onChange={handleChange}
                            className={`form-control ${errors.dateBirth && "is-invalid input-error"} text-light`}
                            id="birthdate"
                        />
                        {errors.dateBirth &&
                            <div id="validationServer05Feedback" class="invalid-feedback">
                                {errors.dateBirth}
                            </div>
                        }
                    </div>
                    <div className="mb-3">
                        <label for="gender" className="form-label">GENDER</label>
                        <select
                            id="gender"
                            name="gender"
                            value={formValues.gender}
                            onChange={handleChange}
                            className={`form-select ${errors.gender && "is-invalid input-error"} text-light`}>
                            <option selected value="">Seleccione una opción</option>
                            <option value="Masculino">Hombre</option>
                            <option value="Femenino">Mujer</option>
                            <option value="Otro">Otro</option>

                        </select>
                        {errors.gender &&
                            <div id="validationServer05Feedback" class="invalid-feedback">
                                {errors.gender}
                            </div>
                        }
                    </div>
                    <div className="mb-3">
                        <label for="phoneNumber" className="form-label">PHONE NUMBER</label>
                        <input
                            type="tel"
                            name="phoneNumber"
                            value={formValues.phoneNumber}
                            onChange={handleChange}
                            className={`form-control ${errors.phoneNumber && "is-invalid input-error"} text-light`}
                            id="phoneNumber"
                            placeholder="your phone number"
                        />
                        {errors.phoneNumber &&
                            <div id="validationServer05Feedback" class="invalid-feedback">
                                {errors.phoneNumber}
                            </div>
                        }
                    </div>
                </div>

                <div className="col-auto d-flex justify-content-center">
                    <button type="submit" className="btn btn-primary mb-3">CREATE</button>
                </div>
            </form>
        </div>
    );
};

export default FormValidateInfo;