// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../assets/styles/global.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/backgrounds/bg-validateInfo.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_verified {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

}

.container-form-info {
    background: rgba(0, 0, 0, 60%);
    flex-shrink: 0;
    min-height: 600px;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: white;
    margin-top: 5vh;
    overflow: hidden;
    box-shadow: 5px 5px 10px -5px var(--bs-primary);
}`, "",{"version":3,"sources":["webpack://./src/Views/ValidateInfo/validateInfo.css"],"names":[],"mappings":"AAEA;IACI,yDAA6E;IAC7E,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,sBAAsB;;AAE1B;;AAEA;IACI,8BAA8B;IAC9B,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,+CAA+C;AACnD","sourcesContent":["@import '../../assets/styles/global.css';\n\n.container_verified {\n    background-image: url('../../assets/images/backgrounds/bg-validateInfo.webp');\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    width: 100%;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n\n}\n\n.container-form-info {\n    background: rgba(0, 0, 0, 60%);\n    flex-shrink: 0;\n    min-height: 600px;\n    border-radius: 50px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    color: white;\n    margin-top: 5vh;\n    overflow: hidden;\n    box-shadow: 5px 5px 10px -5px var(--bs-primary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
