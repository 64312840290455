import './progressBar.css'
//Assets

const ProgressBar = () => {
    return (
        <>
            <div className=''>
                <div className="progress-bar bg-primary">
                </div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>

            </div>
        </>
    );
};

export default ProgressBar;