import React from "react";
//Components
import CardTestimonial from "../../components/cardTestiomonial/CardTestimonial";


const Testiomonials = () => {

    const dataTestiomonial = [
        {
            comment: "I can't thank Nutritionist enough for their personalized nutrition coaching. It has completely transformed my approach to food and helped me shed those extra pounds. Highly recommended!",
            nameUser: "Jennifer Anderson",
            urlPhoto: "",
            usrId:1
        },
        {
            comment: "Nutritionist has been a game-changer for me. The expert guidance and support I received from their team made my weight loss journey so much easier. Thank you!",
            nameUser: "Robert Johnson",
            urlPhoto: "",
            usrId:2

        },
        {
            comment: "I had struggled with my weight for years until I found Nutritionist. Their personalized approach and tailored nutrition plan made all the difference. I've never felt better!",
            nameUser: "Emily Davis",
            urlPhoto: "",
            usrId:3

        }
    ]

    return (
        <>
            <section className="container">
                <section className="">
                    <h3 className="d-flex justify-content-center">Our Testimonials</h3>
                    <p className="d-flex justify-content-center">Our satisfied clients share their success stories and experiences on their journey to better health and well-being.</p>
                </section>
                <section className="d-flex">
                    <CardTestimonial dataTestiomonial={dataTestiomonial}/>
                </section>
                <section><i></i></section>
            </section>
        </>
    );
}

export default Testiomonials;