import React from 'react'
import './footer.css'

//Assets
import iconBlack from "../../assets/images/RADARIA-LOGOTIPO02-BLACK.webp"

import { Link } from 'react-router-dom'
import btnTop from "../../assets/images/ButtonTop.webp"


const Footer = (idViewTop) => {
    return (
        <>
            <div className="container-fluid container-footer d-flex flex-column justify-content-center pt-5 pb-5">
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-2  col-8"><img src={iconBlack} alt="Logo Radaria" width={"150px"} /></div>
                        <div className='col-lg-8 col-8 vertical-align-middle ' >
                            <div className='row justify-content-center align-items-center'>
                                <h6 className='col-lg-2 col-8'>ABOUT US</h6>
                                <h6 className='col-lg-2 col-8'>MEMBERSHIP</h6>
                                <h6 className='col-lg-2 col-8'>COACHES</h6>
                                <h6 className='col-lg-2 col-8'>SERVICES</h6>
                                <h6 className='col-lg-2 col-8'>SIGN IN</h6>
                            </div>
                        </div>
                        <div className='col-lg-2 col-8 d-flex align-items-center justify-content-center'><span className='d-flex me-3'>Go To Top </span> <Link to="#Home"><img src={btnTop} alt="topBtn" /></Link></div>
                    </div>
                </div>
                <div className="container mt-5">
                    <div className='row'>
                        <div className='col-lg-7 d-flex justify-content-around'>
                            <Link className='col-lg-3 btn border-dark ' to="#" >+52 645645645645</Link>
                            <Link className='col-lg-3 btn border-dark' to='#'>contacto@radaria.mx</Link>
                            <Link className='col-lg-3 btn border-dark' to='#'>Polanco, CDMX,5260</Link>
                        </div>
                        <div className='col-lg-5 d-flex justify-content-center'>
                            <span>&copy;2024 Radaria. All rights reserverd</span>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Footer;