import React from "react";

const CardTestimonial = ({ dataTestiomonial }) => {

    return (
        <>
            {
                dataTestiomonial?.map((data) => {
                    return (
                        <div className="card m-5" key={data.usrId}>
                            <div className="card-body">
                                <h5>"</h5>
                                <p className="card-text">
                                    {data.comment}
                                </p>
                            </div>
                            <hr />
                            <div className="d-flex">
                                <img src={data.urlPhoto} alt="img user"/>
                                <h3>{data.nameUser}</h3>
                            </div>
                        </div>
                    )

                })
            }
        </>
    );
}

export default CardTestimonial;