import apiClient from '../api/apiClient';

const registerUser = async (userData) => {
    try {
        const response = await apiClient.post("/register/newUser", userData);
        return response.data;
    } catch (error) {
        console.log(error)

    };
};

const completRegister = async (userData) => {
    try {
        const response = await apiClient.put("/register/verifiUser", userData);
        return response.data;
    } catch (error) {
        console.log(error)

    };
};
export default { registerUser, completRegister };